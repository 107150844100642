<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapRegItems"
    :api="api"
    #default="{items}"
  >
    <regulations-list
      :items="items"
      :ownApi="false"
    />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import RegulationsList from '../../Regulations/RegulationsList';
import { mapRegItems } from '../../Regulations/mapRegItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    RegulationsList,
  },
  metaInfo() {
    return {
      title: 'Monitoring rozporządzeń',
    };
  },
  data() {
    return {
      api: {
        moduleId: 'pk_reg',
        modules: 'module_regulation_header',
        favoriteHated: 'favorite_and_hated_regulation_header',
        favoriteHatedResponse: 'favorite_and_hateful_regulations_list',
        bookmarks: 'bookmarks/fetchRegulations',
        list: 'regulations',
      },
      mapRegItems,
    };
  }
};
</script>
